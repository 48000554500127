<script setup lang="ts">
import { useEmitter } from '@/use/emitter';

const route = useRoute();
const emitter = useEmitter();
const state = reactive({
  search: '',
  dates: {
    startedAt: null,
    endedAt: null,
  },
});

const reset = () => {
  state.search = '';
  state.dates.startedAt = null;
  state.dates.endedAt = null;
};

const onSearch = () => {
  emitter.emit('table-filter', { type: 'search', value: state.search });
};

const onDates = () => {
  emitter.emit('table-filter', { type: 'dates', value: state.dates });
};

watch(
  () => route.name,
  () => {
    reset();
  },
);
</script>

<template>
  <section class="actions flex flex-ic">
    <div class="actions-item">
      <common-input
        v-model="state.search"
        type="text"
        placeholder="Поиск..."
        class-mods="text-sm"
        @key-up-enter="onSearch"
      ></common-input>
    </div>
    <div class="actions-item">
      <common-calendar :dates="state.dates" @apply="onDates"></common-calendar>
    </div>
  </section>
</template>

<style scoped lang="scss">
.actions {
  @apply gap-4;
}
</style>
