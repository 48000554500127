<script setup lang="ts"></script>

<template>
  <div class="transaction-replenishment">
    <common-drop :container="{ top: '48px', right: '0px' }">
      <template #header="{ show }">
        <common-button text="Пополнить" class-mods="btn-accent btn-fit btn-small"></common-button>
      </template>
      <template #container>
        <common-page-header-profile-account-action-container></common-page-header-profile-account-action-container>
      </template>
    </common-drop>
  </div>
</template>
