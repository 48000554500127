<script setup lang="ts"></script>

<template>
  <section class="transaction">
    <common-page-header></common-page-header>
    <section class="transaction-container flex-col">
      <transaction-header></transaction-header>
      <slot></slot>
    </section>
  </section>
</template>

<style lang="scss" scoped>
.transaction {
  @apply padding-top-for-fixed-header;

  &-container {
    @apply px-[10%] py-[5%] py-8 gap-8 h-full;
  }
}
</style>
